<template>
  <div>
    <b-overlay
      :show="Orders.is_loading"
      spinner-variant="primary"
      variant="transparent"
      style="min-height: 125px; z-index: 1"
    >
      <b-card
        v-if="!Orders.is_loading"
        v-for="(item, i) in Orders.items"
        :key="i"
        no-body
        style="padding: 24px"
        class="orders-table"
      >
        <div class="d-flex flex-column">
          <p class="item-offer-title">{{ item.offer.title }}</p>
          <div class="item-offer-buttons">
            <b-button
              class="item-offer-button-edit"
              :href="partnerUrl(item)"
              target="_blank"
              variant="primary"
              style="font-size: 14px; border-radius: 60px; padding: 6px 16px"
            >
              Редактировать
            </b-button>
            <b-button
              class="item-offer-button-delete"
              @click="del(item, i)"
              variant="danger"
              style="font-size: 14px; border-radius: 60px; padding: 6px 16px"
            >
              Удалить
            </b-button>
          </div>
        </div>
        <div class="item-details mmt-8">
          <div class="details-row d-flex justify-content-between">
            <div class="details-name">ID заявки</div>
            <div class="details-value">{{ item.ouid }}</div>
          </div>
          <div class="details-row d-flex justify-content-between">
            <div class="details-name">Данные заявки</div>
            <div class="details-value">
              {{ item.first_name }}
              {{ item.middle_name }}
              {{ item.last_name }}
              <br />
              {{ item.phone_number }}
            </div>
          </div>
          <div class="details-row d-flex justify-content-between">
            <div class="details-name">Дата создания заявки</div>
            <div class="details-value">
              {{ item.date }}
              <br />
              {{ item.time }}
            </div>
          </div>
          <div class="details-row d-flex justify-content-between">
            <div class="details-name">Статус</div>
            <div class="details-value">
              <div class="d-flex align-items-center" v-if="item.status == 1">
                <div class="cicle-badge-view cicle-outline-default"></div>
                <span>Переход/черновик</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 2"
              >
                <div class="cicle-badge-view cicle-outline-warning"></div>
                <span>Ожидает обработки</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 3"
              >
                <div class="cicle-badge-view cicle-warning"></div>
                <span>В обработке</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 4"
              >
                <div class="cicle-badge-view cicle-warning"></div>
                <span>В холде</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 5"
              >
                <div class="cicle-badge-view cicle-outline-success"></div>
                <span>Начислено</span>
              </div>
            </div>
          </div>
          <div class="details-row d-flex justify-content-between">
            <div class="details-name">Доход</div>
            <div class="details-value">
              {{ item.income.formatMoney("", "", "") + " ₽" }}
            </div>
          </div>
        </div>
      </b-card>
      <template v-if="!Orders.is_loading && Orders.items.length === 0">
        <div
          class="d-flex flex-column justify-content-center"
          style="padding: 24px"
        >
          <div class="nodata-icon ml-auto mr-auto"></div>
          <p class="text-muted mt-1 ml-auto mr-auto text-center">
            Нет данных для отображения
          </p>
        </div>
      </template>
      <b-card-body
        v-if="!Orders.is_loading && Orders.items.length > 0"
        class="d-flex justify-content-between pt-0 pb-0"
      >
        <span class="font-weight-bolder">Всего: {{ Orders.total_count }}</span>
        <b-pagination-nav
          :value="Orders.current_page"
          :link-gen="linkGen"
          :number-of-pages="Orders.last_page"
          use-router
        ></b-pagination-nav>
      </b-card-body>
    </b-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Orders: Object,
  },
  methods: {
    linkGen(pageNum) {
      return {
        name: this.$route.name,
        query: {
          page: pageNum,
        },
      };
    },
    edit(ouid) {},
    partnerUrl(item) {
      return (
        item.offer.url +
        "?utm_source=2work" +
        "&utm_medium=" +
        item.ouid +
        "&utm_campaign=" +
        item.user
      );
    },
    del(item, index) {
      this.$swal({
        title: `Вы уверены, что хотите удалить заявку ${item.ouid}?`,
        width: 600,
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Удалить",
        cancelButtonText: "Отмена",
        customClass: {
          popup: "conf-popup",
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$request
            .post("orders.delete", {
              id: item.id,
            })
            .then((rsp) => {
              this.Orders.list();
              this.$swal({
                icon: "success",
                title: item.name,
                text: "Заявка успешно удалена",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },
  },
  components: {},
  watch: {
    "$route.query": function () {
      this.Orders.list(this.$route.query.page);
    },
  },
};
</script>

<style lang="scss">
.item-offer-buttons {
  @media screen and (max-width: 744px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.orders-table {
  margin-bottom: 8px !important;
}
</style>
