<template>
  <b-card no-body style="padding: 24px 0px" class="orders-table">
    <b-skeleton-table
      v-if="Orders.is_loading"
      animation="fade"
      :columns="7"
      :rows="5"
    >
    </b-skeleton-table>

    <b-table-simple v-else>
      <b-thead>
        <b-tr>
          <b-th class="text-nowrap">ID заявки</b-th>
          <b-th>Продукт</b-th>
          <b-th>Данные заявки</b-th>
          <b-th>Дата создания заявки</b-th>
          <b-th>Статус</b-th>
          <b-th>Доход</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <div
          v-for="(item, i) in Orders.items"
          :key="i"
          v-ripple
          class="font-weight-normal"
          style="display: contents"
        >
          <b-tr>
            <b-td class="font-weight-normal">
              {{ item.ouid }}
            </b-td>
            <b-td class="font-weight-normal">
              {{ item.offer.title }}
            </b-td>
            <b-td class="font-weight-normal">
              {{ item.first_name }}
              {{ item.middle_name }}
              {{ item.last_name }}
              <br />
              {{ item.phone_number }}
            </b-td>
            <b-td class="font-weight-normal">
              {{ item.date }}
              <br />
              {{ item.time }}
            </b-td>
            <b-td class="font-weight-normal">
              <div class="d-flex align-items-center" v-if="item.status == 0">
                  <div class="cicle-badge-view cicle-outline-default"></div>
                  <span>Отклонено</span>
              </div>
              <div class="d-flex align-items-center" v-if="item.status == 1">
                <div class="cicle-badge-view cicle-outline-default"></div>
                <span>Переход/черновик</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 2"
              >
                <div class="cicle-badge-view cicle-outline-warning"></div>
                <span>Ожидает обработки</span>
              </div>
              <div
                class="d-flex align-items-center"
                v-else-if="item.status == 3"
              >
                <div class="cicle-badge-view cicle-warning"></div>
                <span>В обработке</span>
              </div>
              <div class="d-flex align-items-center" v-else-if="item.status == 4">
                  <div class="cicle-badge-view cicle-warning"></div>
                <span>В холде</span>
              </div>
              <div class="d-flex align-items-center" v-else-if="item.status == 5">
                  <div class="cicle-badge-view cicle-outline-success"></div>
                <span>Начислено</span>
              </div>
            </b-td>
            <b-td class="font-weight-normal text-nowrap">
              {{ item.income.formatMoney('', '', '') + ' ₽' }}
            </b-td>
            <b-td class="font-weight-normal">
              <div class="d-flex justify-content-around align-items-center">
                <!-- @click="edit(item.ouid)" -->
                <b-button
                  :href="partnerUrl(item)"
                  target="_blank"
                  variant="primary"
                  style="
                    font-size: 14px;
                    border-radius: 60px;
                    padding: 6px 16px;
                    margin-right: 4px;
                  "
                  >Редактировать</b-button
                >
                <b-button
                  @click="del(item, i)"
                  variant="danger"
                  style="
                    font-size: 14px;
                    border-radius: 60px;
                    padding: 6px 16px;
                  "
                  >Удалить</b-button
                >
              </div>
            </b-td>
          </b-tr>
        </div>
      </b-tbody>
    </b-table-simple>
    <template v-if="!Orders.is_loading && Orders.items.length === 0">
      <div
        class="d-flex flex-column justify-content-center"
        style="padding: 24px"
      >
        <div class="nodata-icon ml-auto mr-auto"></div>
        <p class="text-muted mt-1 ml-auto mr-auto text-center">
          Нет данных для отображения
        </p>
      </div>
    </template>

    <b-card-body
      v-if="Orders.items.length > 0"
      class="d-flex justify-content-between pt-0 pb-0"
    >
      <span class="font-weight-bolder">Всего: {{ Orders.total_count }}</span>
      <b-pagination-nav
        :value="Orders.current_page"
        :link-gen="linkGen"
        :number-of-pages="Orders.last_page"
        use-router
      ></b-pagination-nav>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Orders: Object,
  },
  methods: {
    linkGen(pageNum) {
      return {
        name: this.$route.name,
        query: {
          page: pageNum
        }
      }      
    },
    edit(ouid) {},
    partnerUrl(item) {
      return (
        item.offer.url +
        '?utm_source=2work' +
        '&utm_medium=' +
        item.ouid +
        '&utm_campaign=' +
        item.user
      );
    },
    del(item, index) {
      this.$swal({
        title: `Вы уверены, что хотите удалить заявку ${item.ouid}?`,
        width: 600,
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Отмена',
        customClass: {
          popup: 'conf-popup',
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$request
            .post('orders.delete', {
              id: item.id,
            })
            .then((rsp) => {
              this.Orders.list();
              this.$swal({
                icon: 'success',
                title: item.name,
                text: 'Заявка успешно удалена',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              });
            });
        }
      });
    },
  },
  components: {},
  watch: {
    "$route.query" : function () {
      this.Orders.list(this.$route.query.page);
    }
  },
};
</script>

<style lang="scss">
.conf-popup {
  border-radius: 24px;
  padding: 32px;

  @media screen and (max-width: 744px) {
    padding: 20px;
  }

  .swal2-header {
    display: block;
    padding: 0;
  }

  .swal2-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    color: #1e2041;
  }

  .swal2-actions {
    align-items: stretch;
    margin-top: 20px;
    display: flex;
    gap: 20px;

    @media screen and (max-width: 744px) {
      flex-direction: column;
      gap: 8px;
    }

    .btn {
      border-radius: 8px;
      flex: 1;
    }
  }
}
</style>
