<template>
  <div class="orders">
    <div class="d-flex justify-content-between mb-2">
      <p class="main-header-section">
        Заявки
        <span
          v-if="
            !Orders.is_loading &&
            Orders.filters.select !== 'today' &&
            Orders.filters.select !== 'yesterday'
          "
        >
          с {{ Orders.o_start }} по {{ Orders.o_end }}
        </span>
      </p>
      <div class="download-outline-btn" @click="exportExcel">
        <feather-icon icon="DownloadIcon" style="margin-right: 8px" size="18" />
        <span>Сохранить отчет</span>
      </div>
    </div>

    <filters-view />

    <table-view :Orders="Orders" />

    <faq-view />
  </div>
</template>

<script>
import Offers from '@/modules/offers/';
import Orders from '@/modules/contents/orders';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import FiltersView from '../widgets/filters';
import faqView from '../widgets/faq';
import tableView from '../tables/desktop';

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      Offers,
      Orders,
    };
  },
  props: {},
  methods: {
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    loadMore() {},
    changeActiveCategories(categories) {},
    exportExcel() {
      if (Orders.items.length > 0)  {
        this.$request.export("export/orders", Orders.filters).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let fileName = 'заявки';
          if (Orders.o_start && Orders.o_start.length > 0) fileName += '_c_'+Orders.o_start;
          if (Orders.o_end && Orders.o_end.length > 0) fileName += '_по_'+Orders.o_end;
          fileName += '.xlsx';
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            text: "Нет данных для для отчета.",
            icon: "CheckCircleIcon",
            variant: "warning",
          },
        });
      }
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    FiltersView,
    tableView,
    faqView,
  },

  computed: {},

  watch: {},
  mounted() {
    this.$router.replace({ query: {} })
    Orders.list();
  },
};
</script>

<style lang="scss"></style>
