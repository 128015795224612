<template>
  <div class="faq">
    <p class="faq-title" data-anime="animate__fadeIn">Ответы на вопросы</p>

    <b-card no-body style="padding: 24px 32px">
      <div class="q-section-block bg-white-color faq-block">
        <div
          class="faq-section animate__animated"
          data-anime="animate__fadeInUp"
          data-delay="200"
        >
          <div class="faq-section-header" v-b-toggle.faq-1>
            <p>Как оформить заявку?</p>
            <feather-icon
              class="when-open"
              icon="MinusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
            <feather-icon
              class="when-closed"
              icon="PlusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
          </div>

          <b-collapse id="faq-1" class="faq-section-content" role="tabpanel">
            <p>
              Оформить заявку можно двумя способами: на странице оффера нажмите
              на кнопку “Оставить заявку” и введите данные подходящего кандидата
              в форму, затем заполните данные на сайте партнера. Если вы хотите
              продвигать оффер в рекламе, то нажмите кнопку “Поделиться
              ссылкой”, затем скопируйте ссылку и используйте ее в рекламе или
              отправьте подходящему кандидату.
            </p>
            <alert-card-view
              :warning="true"
              text="Заявка обязательно должна быть заполнена через сайт 2work, чтобы вам было начислено вознаграждение"
            />
          </b-collapse>
        </div>
        <div
          class="faq-section animate__animated"
          data-anime="animate__fadeInUp"
          data-delay="300"
        >
          <div class="faq-section-header" v-b-toggle.faq-2>
            <p>Какие существуют статусы заявок?</p>
            <feather-icon
              class="when-open"
              icon="MinusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
            <feather-icon
              class="when-closed"
              icon="PlusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
          </div>

          <b-collapse id="faq-2" class="faq-section-content" role="tabpanel">
            <p>Как я могу повысить уровень?</p>
          </b-collapse>
        </div>
        <div
          class="faq-section animate__animated"
          data-anime="animate__fadeInUp"
          data-delay="400"
        >
          <div class="faq-section-header" v-b-toggle.faq-3>
            <p>Как начисляется доход за заявку?</p>
            <feather-icon
              class="when-open"
              icon="MinusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
            <feather-icon
              class="when-closed"
              icon="PlusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
          </div>

          <b-collapse id="faq-3" class="faq-section-content" role="tabpanel">
            <p>??</p>
          </b-collapse>
        </div>
        <div
          class="faq-section animate__animated"
          data-anime="animate__fadeInUp"
          data-delay="400"
        >
          <div class="faq-section-header" v-b-toggle.faq-4>
            <p>Почему заявка может быть не принята?</p>
            <feather-icon
              class="when-open"
              icon="MinusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
            <feather-icon
              class="when-closed"
              icon="PlusCircleIcon"
              style="
                color: var(--link-primary);
                min-width: 20px;
                min-height: 20px;
              "
            />
          </div>

          <b-collapse id="faq-4" class="faq-section-content" role="tabpanel">
            <p>??</p>
          </b-collapse>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import alertCardView from '@/components/AlertCard';

export default {
  data() {
    return {};
  },
  components: {
    alertCardView,
  },
};
</script>

<style lang="scss">
.faq {
  margin-top: 50px;
  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }

  .faq-section-header {
    @media screen and (max-width: 744px) {
      p {
        max-width: 224px;
      }
    }
  }

  .faq-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    font-style: normal;
    @media screen and (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}
</style>
