<template>
    <div>
        <showAt breakpoint="large">
            <desktop-view />
        </showAt>
        <showAt breakpoint="medium">
            <tablet-view />
        </showAt>
        <showAt breakpoint="small">
            <mobile-view />
        </showAt>
    </div>
</template>

<script>


import DesktopView from "./views/desktop"
import TabletView from "./views/tablet"
import MobileView from "./views/mobile"

export default {
    metaInfo() {
        // const title = this.$t('title-pages.main');
        return {
            // title: title,
            title: 'Заявки',
            titleTemplate: null,
        }
    },
    data() {
        return {
        }
    },
    methods: {

    },
    components: {
        DesktopView,
        TabletView,
        MobileView
    },
    watch: {

    },
    mounted() {

    }

}

</script>