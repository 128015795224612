<template>
  <div>
    <div class="date-filters mb-2">
      <div
        class="date-filters__item"
        v-for="(val, index) in filters_date"
        :key="index"
        :class="{ active: val.indicator == Orders.filters.select }"
        @click="select(val)"
      >
        {{ val.title }}
      </div>
    </div>

    <b-card-title>
      <validation-observer
        ref="filter"
        class="slider-hover-prevent v-select-filter-form"
      >
        <showAt breakpoint="small">
          <div class="d-flex flex-column align-items-center gap-8">
            <div class="w-100">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between foc">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Offers.categories"
                        v-model="filters.product"
                        label="name"
                        :clearable="true"
                        placeholder="Выберите сферу"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div class="w-100">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="statuses"
                        v-model="filters.status"
                        label="display"
                        :clearable="true"
                        placeholder="Выберите статус"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </div>

            <div class="w-100 mmt-5">
              <div
                class="w-100 d-flex justify-content-center clear-filter"
                @click="clear"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="18"
                  style="margin-right: 6px"
                />
                <span> Сбросить фильтры </span>
              </div>
            </div>
          </div>
        </showAt>
        <showAt breakpoint="medium">
          <b-row class="align-items-center">
            <b-col>
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between foc">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Offers.categories"
                        v-model="filters.product"
                        label="name"
                        :clearable="true"
                        placeholder="Выберите сферу"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="statuses"
                        v-model="filters.status"
                        label="display"
                        :clearable="true"
                        placeholder="Выберите статус"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col>
              <div class="clear-filter" @click="clear">
                <feather-icon
                  icon="TrashIcon"
                  size="18"
                  style="margin-right: 6px"
                />
                <span> Сбросить фильтры </span>
              </div>
            </b-col>
          </b-row>
        </showAt>
        <showAt breakpoint="large">
          <b-row class="align-items-center">
            <b-col md="3" lg="3">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between foc">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="Offers.categories"
                        v-model="filters.product"
                        label="name"
                        :clearable="true"
                        placeholder="Выберите сферу"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="3" lg="3">
              <b-form-group class="form-group-with-label">
                <div class="d-flex justify-content-between">
                  <div style="flex-grow: 1">
                    <validation-provider rules="">
                      <v-select
                        :options="statuses"
                        v-model="filters.status"
                        label="display"
                        :clearable="true"
                        placeholder="Выберите статус"
                      >
                        <template slot="no-options"> Нет результата </template>
                      </v-select>
                    </validation-provider>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="3" lg="3">
              <div class="clear-filter" @click="clear">
                <feather-icon
                  icon="TrashIcon"
                  size="18"
                  style="margin-right: 6px"
                />
                <span> Сбросить фильтры </span>
              </div>
            </b-col>
          </b-row>
        </showAt>
      </validation-observer>
    </b-card-title>

    <b-modal
      id="fiter-date-modal"
      v-model="modalsVisibility.date"
      centered
      title="Выбор даты"
      hide-footer
    >
      <date-modal-content :Orders="Orders" @changePeriod="changePeriod" />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';

import Offers from '@/modules/offers';
import Orders from '@/modules/contents/orders';

import dateModalContent from '../modals/date';

export default {
  data() {
    return {
      Offers,
      Orders,
      modalsVisibility: {
        date: false,
      },
      filters: {
        product: null,
        status: null,
      },
      statuses: [
        { value: 1, display: 'Переход/черновик' },
        { value: 2, display: 'Ожидает обработки' },
        { value: 3, display: 'В обработке' },
        { value: 4, display: 'Отклонено' },
        { value: 5, display: 'Начислено' },
      ],
      filters_date: [
        {
          indicator: 'today',
          title: 'Сегодня',
        },
        {
          indicator: 'yesterday',
          title: 'Вчера',
        },
        {
          indicator: 'week',
          title: 'За неделю',
        },
        {
          indicator: 'month',
          title: 'За месяц',
        },
        {
          indicator: 'all',
          title: 'За все время',
        },
        {
          indicator: 'period',
          title: 'Свой период',
        },
      ],
    };
  },
  methods: {
    changePeriod() {
      Orders.list();
      this.modalsVisibility.date = false;
    },
    select(val) {
      this.$router.replace({ query: {} })
      Orders.filters.select = val.indicator;
      if (val.indicator == 'period') {
        this.modalsVisibility.date = true;
      } else {
        Orders.list();
      }
    },
    clear() {
      if (this.filters.product || this.filters.status) {
        this.filters = {
          product: null,
          status: null,
        };
      }
    },
  },
  mounted() {
    this.filters = {
      product: Orders.filters.product,
      status: Orders.filters.status
    };
    if (Offers.categories.length == 0) Offers.getCategories();
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    dateModalContent,
  },
  watch: {
    filters: {
      deep: true,
      handler(bef) {
        Orders.filters.product = this.filters.product;
        Orders.filters.status = this.filters.status;
        Orders.list();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.date-filters {
  display: flex;

  @media screen and (max-width: 744px) {
    flex-wrap: wrap;
    gap: 4px;
  }

  &__item {
    cursor: pointer;
    margin-right: 4px;
    background: transparent;
    border: 1px solid var(--link-primary);
    border-radius: 60px;
    padding: 6px 14px;
    color: var(--link-primary);

    @media screen and (max-width: 744px) {
      margin: 0;
    }
  }

  &__item.active {
    color: #fff;
    background-color: var(--link-primary);
  }
}

.clear-filter {
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 400;
}
</style>
