<template>
  <b-card no-body class="d-flex flex-row align-items-center align-items-sm-start alert-card"
    style="padding: 16px 20px; background: #F6F8FB;">
    <div v-if="warning" class="icon-warning" style="padding-right: 50px"></div>
    <b-card-text class="text-muted">
      {{ text }}
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: {
    text: String,
    warning: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.alert-card {
  border-radius: 24px !important; 
}
.dark-layout .alert-card {
  background: #171733 !important; 
}
</style>
